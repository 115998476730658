import { homeUrl } from "./apiEndPoints";
const cookies = require("react-cookies");
const fetchRequest = (options) => {
	return new Promise((resolve, reject) => {
		let url = new URL(options.url);

		options = {
			...options,
			...(options.body && { data: options.body }),
			url: url.href,
			headers: {
				...options.headers,
				"Accept-Language": `${
					cookies.load("lang") === "eng" ? "en-us" : "id"
				}`,
			}
		};
		
		if (
			cookies.load("applicantauthtoken", { domain: homeUrl }) &&
			cookies.load("applicantrefreshtoken", { domain: homeUrl })
		) {
			options = {
				...options,
				headers: {
					...options.headers,
					Authorization: `Bearer ${cookies.load("applicantauthtoken")}`,
					// refresh: `${cookie.load('applicantrefreshtoken')}`,
				},
			};
		}
		let newOps = {
			method: options.method,
			json: true,
			withCredentials: true,
		};
		if (options.headers) {
			newOps.headers = options.headers;
		}
		const formData = new FormData();
		if (options.body) {
			Object.keys(options.body).forEach((key) => {
				if(Array.isArray(options.body[key])) {
					options.body[key]?.forEach((items) => {
						formData.append(key, JSON.stringify(items));
					});
				} else {
					formData.append(key, options.body[key]);
				}
			});
			newOps.body = formData;
		}

		fetch(options.url, newOps)
			.then(async (response) => {
				let apiResponse = await response.json();
				if (
					apiResponse.status_code === 401 &&
					cookies.load("applicantauthtoken") &&
					cookies.load("applicantrefreshtoken")
				) {
					await cookies.remove("lang", { path: "/", domain: homeUrl });
					await cookies.remove("applicantauthtoken", { path: "/", domain: homeUrl });
					await cookies.remove("applicantrefreshtoken", {
						path: "/",
						domain: homeUrl,
					});
					await cookies.remove("applicanttokenexpiry", { path: "/", domain: homeUrl });
					setTimeout(() => {
						window.location.reload();
					}, 200);
				} else {
					resolve(apiResponse);
				}
			})
			.catch(async (err) => {
				let apiResponse;
				if (err) {
					try {
						apiResponse = err.json();
					} catch (e) {
						apiResponse = err;
					}
				}
				if (
					apiResponse.status_code === 401 &&
					cookies.load("applicantauthtoken") &&
					cookies.load("applicantrefreshtoken")
				) {
					await cookies.remove("lang", { path: "/", domain: homeUrl });
					await cookies.remove("applicantauthtoken", { path: "/", domain: homeUrl });
					await cookies.remove("applicantrefreshtoken", {
						path: "/",
						domain: homeUrl,
					});
					await cookies.remove("applicanttokenexpiry", { path: "/", domain: homeUrl });
					setTimeout(() => {
						window.location.reload();
					}, 200);
				} else {
					resolve(apiResponse);
				}
			});
	});
};

export default fetchRequest;
